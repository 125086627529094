
import React, { useState, useEffect } from 'react'; 
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { myRoutes } from "routes";
import { LocationDropDownWidget } from "components/FicusWidgets";
import { Container } from 'react-bootstrap';
import ReactGA from "react-ga4";


// pages
import DashboardFicusPlayground from "pages/dashboard/DashboardFicusPlayground.js";
import DashboardOperator from "pages/dashboard/DashboardOperator.js";
import DashboardOperatorX from "pages/dashboard/DashboardOperatorx.js";
import MIQCountries from "pages/tables/test-api-tables.js";
import FicusSignIn from "pages/access/Signin.js";
import FicusAdminSignIn from "pages/access/FicusAdminSignin.js";
import FicusAdminHome from "pages/admin/FicusAdminHome.js";
import ChangePassword from "pages/access/ChangePassword.js";
import ResetPassword from "pages/access/ResetPassword.js";
import Profile from "pages/Profile.js";
import MyAccount from "pages/MyAccount.js";
import Contact from "pages/Contact.js";
import Share from "pages/Share.js";
import Inquiry from "pages/Inquiry.js";
import LocationURL from "pages/LocationURL.js";
import StatusListing from "pages/admin/StatusListing.js";
import FAQ from "pages/FAQ.js";
import ContactsHubSpot from "pages/ContactsHubSpot.js";
import Students from "pages/Students.js";
import Loading from "pages/access/Loading.js";
import DocsAbout from "pages/documentation/DocsAbout.js";
import Notifications from "pages/Notifications.js";

import DashboardOverview from "pages/dashboard/DashboardOverview.js";
import DashboardTraffic from "pages/dashboard/DashboardTraffic.js";
import DashboardProductAnalysis from "pages/dashboard/DashboardProductAnalysis.js";
import Kanban from "pages/Kanban.js";
import Messages from "pages/Messages.js";
import SingleMessage from "pages/SingleMessage.js";
import Users from "pages/Users.js";
import Transactions from "pages/Transactions.js";
import Tasks from "pages/Tasks.js";
import Settings from "pages/Settings.js";
import Calendar from "pages/Calendar.js";
import MapPage from "pages/Map.js";
import Datatables from "pages/tables/Datatables.js";
import BootstrapTables from "pages/tables/BootstrapTables.js";
import Pricing from "pages/examples/Pricing.js";
import Billing from "pages/examples/Billing.js";
import Invoice from "pages/examples/Invoice.js";
import Signin from "pages/examples/Signin.js";
import Signup from "pages/examples/Signup.js";
import Lock from "pages/examples/Lock.js";
import Widgets from "pages/examples/Widgets.js";
import NotFoundPage from "pages/examples/NotFound.js";
import ServerError from "pages/examples/ServerError.js";

// documentation pages
import DocsOverview from "pages/documentation/DocsOverview.js";
import DocsDownload from "pages/documentation/DocsDownload.js";
import DocsQuickStart from "pages/documentation/DocsQuickStart.js";
import DocsLicense from "pages/documentation/DocsLicense.js";
import DocsFolderStructure from "pages/documentation/DocsFolderStructure.js";
import DocsBuild from "pages/documentation/DocsBuild.js";
import DocsChangelog from "pages/documentation/DocsChangelog.js";

// plugin pages
import PluginSignatureCanvas from "pages/plugins/SignatureCanvas.js";
import PluginCharts from "pages/plugins/Charts.js";
import PluginCalendar from "pages/plugins/Calendar.js";
import PluginDatatable from "pages/plugins/Datatable.js";
import PluginMap from "pages/plugins/Map.js";
import PluginDropzone from "pages/plugins/Dropzone.js";
import PluginSweetAlert from "pages/plugins/SweetAlert.js";

// components
import Sidebar from 'components/Sidebar';
import Topbar from 'components/Topbar';
import Footer from 'components/Footer';

import Accordion from "pages/components/Accordion.js";
import Alerts from "pages/components/Alerts.js";
import Badges from "pages/components/Badges.js";
import Breadcrumbs from "pages/components/Breadcrumbs.js";
import Buttons from "pages/components/Buttons.js";
import Forms from "pages/components/Forms.js";
import Modals from "pages/components/Modals.js";
import Navs from "pages/components/Navs.js";
import Navbars from "pages/components/Navbars.js";
import Pagination from "pages/components/Pagination.js";
import Popovers from "pages/components/Popovers.js";
import Progress from "pages/components/Progress.js";
import Tables from "pages/components/Tables.js";
import Tabs from "pages/components/Tabs.js";
import Tooltips from "pages/components/Tooltips.js";
import Toasts from "pages/components/Toasts.js";
import WidgetsComponent from "pages/components/Widgets.js";

import { useAuth } from "pages/access/useAuth"; 

const RouteWithSidebar = ({ component: Component, ...rest }) => {
	
  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  }

  const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'false' ? false : true
  }

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem('sidebarContracted', !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
      <>
        <Sidebar
          contracted={contractSidebar}
          onMouseEnter={toggleMouseOver}
          onMouseLeave={toggleMouseOver}
        />

        <main className="content">
	          <Topbar toggleContracted={toggleContracted} />
	          <Component {...rest} />
	          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>

  );
};

const RouteWithTopbar = ({locationNav: LocationNav, component: Component, ...rest }) => {

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }
  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }
  
  return (
      <>
        <main className="content">
	          <Topbar toggleContracted={true} />
      			<Container className="d-block">
		          {/* <ShowLocationNav /> */}
		          <Component {...rest} />
		        </Container>
	          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
	)
};


export default function HomePage(props) {
		const auth = useAuth();
		const [isLoading, setIsLoading] = useState(props.value);

	  const location = useLocation();
	  useEffect(() => {
	    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: 'Path'+location.pathname + location.search });

	  }, [location]);

// If there's not an authorized user, route everything to the signin or pages not requiring authentication.	

/*
			if (isLoading && !auth.user) {
			return(
				<Routes>
		  		<Route path="*" element={<Loading />} />
		  	</Routes>			
			)
		}
*/	
		if (!auth.user) { 
			return( 
				<>
				<Routes>
			    <Route exact path={myRoutes.ResetPassword.path} element={<ResetPassword />} />
			    <Route exact path={myRoutes.ChangePassword.path} element={<ChangePassword />} />
			    <Route path={myRoutes.LocationURL.path} element={<LocationURL />} />
			    <Route path={myRoutes.StatusListing.path} element={<StatusListing />} />
			    <Route path={myRoutes.Inquiry.path} element={<Inquiry />} />
			    <Route exact path={myRoutes.FicusAdminHome.path} element={<FicusAdminSignIn />} />
			    <Route exact path={myRoutes.FicusAdminSignIn.path} element={<FicusAdminSignIn />} />
	    		<Route path="*" element={<FicusSignIn />} />
	    	</Routes>
	    	</>
	    )		
	  } else if (auth.user.persontype === 100) {
			return( 
				<>
				<Routes>
			    <Route exact path={myRoutes.ChangePassword.path} element={<ChangePassword />} />
	    		<Route path="*" element={<FicusAdminHome />} /> 
	    	</Routes>
	    	</>	
	    )  	
		} else {	
			return(
				<>
		  	 <Routes>
			    {/* Production Routes */}
			    <Route exact path={myRoutes.FicusAdminSignIn.path} element={<FicusAdminSignIn />} />
	    		<Route exact path={myRoutes.FicusAdminHome.path} element={<FicusAdminHome />} />
			    <Route exact path={myRoutes.DashboardOperator.path} element={<RouteWithTopbar locationNav={LocationDropDownWidget} component={DashboardOperator} />} />
			    <Route exact path={"/"} element={<RouteWithTopbar locationNav={LocationDropDownWidget} component={DashboardOperator} />} />
			    <Route exact path={myRoutes.DashboardOperatorX.path} element={<RouteWithTopbar component={DashboardOperatorX} />} />
			    <Route exact path={myRoutes.Profile.path} element={<RouteWithTopbar component={Profile} />} />
			    <Route exact path={myRoutes.MyAccount.path} element={<RouteWithTopbar component={MyAccount} />} />
			    <Route exact path={myRoutes.Contact.path} element={<RouteWithTopbar component={Contact} />} />
			    <Route exact path={myRoutes.Share.path} element={<RouteWithTopbar component={Share} />} />
			    <Route path={myRoutes.Inquiry.path} element={<Inquiry />} />
			    <Route path={myRoutes.LocationURL.path} element={<LocationURL />} />
			    <Route path={myRoutes.StatusListing.path} element={<StatusListing />} />
			    <Route exact path={myRoutes.FAQ.path} element={<RouteWithTopbar component={FAQ} />} />
			    <Route exact path={myRoutes.ContactsHubSpot.path} element={<RouteWithSidebar component={ContactsHubSpot} />} />
			    <Route exact path={myRoutes.Students.path} element={<RouteWithTopbar locationNav={LocationDropDownWidget} component={Students} />} />
			    <Route exact path={myRoutes.DocsAbout.path} element={<RouteWithTopbar component={DocsAbout} />} />
			    <Route exact path={myRoutes.Notifications.path} element={<RouteWithTopbar component={Notifications} />} />

			    {/* Production Routes - temporarily in the signedin section for testing */}			    
	    		<Route path="*" element={<NotFoundPage />} />} />

			    <Route exact path={myRoutes.Signin.path} element={<Signin />} />
			    <Route exact path={myRoutes.Signup.path} element={<Signup />} /> 
			    <Route exact path={myRoutes.ResetPassword.path} element={<ResetPassword />} />
			    <Route exact path={myRoutes.ChangePassword.path} element={<ChangePassword />} />
			    <Route exact path={myRoutes.Lock.path} element={<Lock />} />
			    <Route exact path={myRoutes.NotFound.path} element={<NotFoundPage />} />
			    <Route exact path={myRoutes.ServerError.path} element={<ServerError />} /> 



			    {/* pages */}
			    <Route exact path={myRoutes.DashboardOverview.path} element={<RouteWithSidebar component={DashboardOverview} />} />
			    <Route exact path={myRoutes.DashboardTraffic.path} element={<RouteWithSidebar component={DashboardTraffic} />} />
			    <Route exact path={myRoutes.DashboardProductAnalysis.path} element={<RouteWithSidebar component={DashboardProductAnalysis} />} />
			    <Route exact path={myRoutes.DashboardFicusPlayground.path} element={<RouteWithSidebar component={DashboardFicusPlayground} />} />
			    <Route exact path={myRoutes.Kanban.path} element={<RouteWithSidebar component={Kanban} />} />
			    <Route exact path={myRoutes.Tasks.path} element={<RouteWithSidebar component={Tasks} />} />
			    <Route exact path={myRoutes.Messages.path} element={<RouteWithSidebar component={Messages} />} />
			    <Route exact path={myRoutes.SingleMessage.path} element={<RouteWithSidebar component={SingleMessage} />} />
			    <Route exact path={myRoutes.Users.path} element={<RouteWithSidebar component={Users} />} />
			    <Route exact path={myRoutes.Transactions.path} element={<RouteWithSidebar component={Transactions} />} />
			    <Route exact path={myRoutes.Settings.path} element={<RouteWithSidebar component={Settings} />} />
			    <Route exact path={myRoutes.Calendar.path} element={<RouteWithSidebar component={Calendar} />} />
			    <Route exact path={myRoutes.Map.path} element={<RouteWithSidebar component={MapPage} />} />
			    <Route exact path={myRoutes.Datatables.path} element={<RouteWithSidebar component={Datatables} />} />
			    <Route exact path={myRoutes.BootstrapTables.path} element={<RouteWithSidebar component={BootstrapTables} />} />
			    <Route exact path={myRoutes.MIQCountries.path} element={<RouteWithSidebar component={MIQCountries} />} />
			    <Route exact path={myRoutes.Pricing.path} element={<RouteWithSidebar component={Pricing} />} />
			    <Route exact path={myRoutes.Billing.path} element={<RouteWithSidebar component={Billing} />} />
			    <Route exact path={myRoutes.Invoice.path} element={<RouteWithSidebar component={Invoice} />} />

			    {/* components */}
			    <Route exact path={myRoutes.Accordions.path} element={<RouteWithSidebar component={Accordion} />} />
			    <Route exact path={myRoutes.Alerts.path} element={<RouteWithSidebar component={Alerts} />} />
			    <Route exact path={myRoutes.Badges.path} element={<RouteWithSidebar component={Badges} />} />
			    <Route exact path={myRoutes.Widgets.path} element={<RouteWithSidebar component={Widgets} />} />
			    <Route exact path={myRoutes.Breadcrumbs.path} element={<RouteWithSidebar component={Breadcrumbs} />} />
			    <Route exact path={myRoutes.Buttons.path} element={<RouteWithSidebar component={Buttons} />} />
			    <Route exact path={myRoutes.Forms.path} element={<RouteWithSidebar component={Forms} />} />
			    <Route exact path={myRoutes.Modals.path} element={<RouteWithSidebar component={Modals} />} />
			    <Route exact path={myRoutes.Navs.path} element={<RouteWithSidebar component={Navs} />} />
			    <Route exact path={myRoutes.Navbars.path} element={<RouteWithSidebar component={Navbars} />} />
			    <Route exact path={myRoutes.Pagination.path} element={<RouteWithSidebar component={Pagination} />} />
			    <Route exact path={myRoutes.Popovers.path} element={<RouteWithSidebar component={Popovers} />} />
			    <Route exact path={myRoutes.Progress.path} element={<RouteWithSidebar component={Progress} />} />
			    <Route exact path={myRoutes.Tables.path} element={<RouteWithSidebar component={Tables} />} />
			    <Route exact path={myRoutes.Tabs.path} element={<RouteWithSidebar component={Tabs} />} />
			    <Route exact path={myRoutes.Tooltips.path} element={<RouteWithSidebar component={Tooltips} />} />
			    <Route exact path={myRoutes.Toasts.path} element={<RouteWithSidebar component={Toasts} />} />
			    <Route exact path={myRoutes.WidgetsComponent.path} element={<RouteWithSidebar component={WidgetsComponent} />} />

			    {/* documentation */}
			    <Route exact path={myRoutes.DocsOverview.path} element={<RouteWithSidebar component={DocsOverview} />} />
			    <Route exact path={myRoutes.DocsDownload.path} element={<RouteWithSidebar component={DocsDownload} />} />
			    <Route exact path={myRoutes.DocsQuickStart.path} element={<RouteWithSidebar component={DocsQuickStart} />} />
			    <Route exact path={myRoutes.DocsLicense.path} element={<RouteWithSidebar component={DocsLicense} />} />
			    <Route exact path={myRoutes.DocsFolderStructure.path} element={<RouteWithSidebar component={DocsFolderStructure} />} />
			    <Route exact path={myRoutes.DocsBuild.path} element={<RouteWithSidebar component={DocsBuild} />} />
			    <Route exact path={myRoutes.DocsChangelog.path} element={<RouteWithSidebar component={DocsChangelog} />} />

			    {/* plugins */}
			    <Route exact path={myRoutes.PluginSignatureCanvas.path} element={<RouteWithSidebar component={PluginSignatureCanvas} />} />
			    <Route exact path={myRoutes.PluginCharts.path} element={<RouteWithSidebar component={PluginCharts} />} />
			    <Route exact path={myRoutes.PluginCalendar.path} element={<RouteWithSidebar component={PluginCalendar} />} />
			    <Route exact path={myRoutes.PluginDatatable.path} element={<RouteWithSidebar component={PluginDatatable} />} />
			    <Route exact path={myRoutes.PluginMap.path} element={<RouteWithSidebar component={PluginMap} />} />
			    <Route exact path={myRoutes.PluginDropzone.path} element={<RouteWithSidebar component={PluginDropzone} />} />
			    <Route exact path={myRoutes.PluginSweetAlert.path} element={<RouteWithSidebar component={PluginSweetAlert} />} />
			    
			    <Route path="/examples/404" element={<Navigate to={myRoutes.NotFound.path} />} />
			  </Routes>
				</>
			);
		};
};
