
import React, { useState, useEffect, useRef } from "react";
import moment from 'moment-timezone';
import axios from "axios";
import { UserAddIcon } from "@heroicons/react/solid";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { Button, Badge, Card, ListGroup, Row, Col, Form, Modal, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useAuth } from "pages/access/useAuth"; 

export const ContractWidget = () => {
	const { 
		user,
		endpoint
	} = useAuth();  
	
	const [contractInfo, setContractInfo] = useState([]);
	
	useEffect(() => {
		axios.get(endpoint+'/company/operator/default.asp', {
			params: { 'companyid': user.companyid }	    	
		
	  })
	  .then(response => {
	  	const result = response.data[0];
	  	console.log(result);
	  	setContractInfo(result);
		})
	  	.catch(error => console.log(error));	
	},[user, endpoint]);
	  	  
  return (
    <Card className="text-center mb-4 mb-lg-0">
      <Card.Body>
        <h5 className=" mb-3">{contractInfo.companyname}</h5>
        <div className="text-start small">Subscription Start Date:<b> {contractInfo.contractstart}</b></div>        
        <div className="text-start small">Subscription End Date:<b> {contractInfo.contractend}</b></div>        
        <div className="text-start small">Pricing Tier:<b> {contractInfo.pricingtier}</b></div>
{/*
        <Card.Link as={Button} size="sm" className="btn-primary text-white text-center me-2 mt-4" style={{pointerEvents: 'none'}}>
          View Subscription Agreement
        </Card.Link>
        <p style={{fontSize: "0.7rem"}}><i>Agreement view not available - coming soon</i></p>
*/}
      </Card.Body>
    </Card>
  );
}

export const AuthorizedUsersWidget = () => {
	const { 
		user,
		endpoint,
		secToken
	} = useAuth();  
	
	const [authorizedUsers, setAuthorizedUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState({});
	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [showChangeAuthModal, setShowChangeAuthModal] = useState(false);

	/* Change Auth Status Modal */
	const ChangeAuthModal  = (props) => {
		const {showme, onClose} = props;
		
	  const navigate = useNavigate();
		 
		const newstatus = selectedUser && selectedUser.PersonTypeID > 0 ? -1 : 3;
		
		function closeMe() {
			onClose();
		}
		
		const changeAuthorization = (newstatus) => {		 		  	
			axios.post(endpoint+'/users/authorizedusers.asp', {}, {
					params: {'sectoken': secToken, 
						'personid':selectedUser.PersonID, 
						'action':'setauthstatus', 
						'persontypeid':newstatus 
					}	
				})
			  .then(response => {
			  	if (response.data === 'success') {
			  		/* Update the authorizedUsers list with new value so that the UI updates */
						const index = authorizedUsers.findIndex((usr) => { return usr.PersonID === selectedUser.PersonID });
						const theUser = authorizedUsers.find((usr) => { return usr.PersonID === selectedUser.PersonID });
			  		theUser.PersonTypeID = newstatus;
			  		const authusercopy = [...authorizedUsers];
			  		authorizedUsers[index] = theUser;
			  		setAuthorizedUsers(authusercopy);		
			  			  		
						alert("Access Authorization Updated");
			    	(onClose) ? onClose() : navigate('/', {replace: true});
			    } else {
			    	alert('There was a problem changing the status.  Please try again.');	    	
			    }
		    })
			.catch(error => console.log(error.response.data));	
				
		};  	
		
		return (
	  <Modal centered show={showme} onHide={() => {}}>
	    <Modal.Header style = {{alignItems: "normal"}}>
	      <Modal.Title>
	      	<div className="float-start">
		      	<h4 className="mb-0">Update Access Authorization</h4>
		      </div>
	      </Modal.Title>
	      <Button variant="close" aria-label="Close" onClick={closeMe} />
	  	</Modal.Header>
	      <Modal.Body>
	      <div className="text-center">
	    		<div className="text-start mb-3">
		      		<h6><b>{(newstatus > 0) ? 'Grant' : 'Revoke'}</b> {selectedUser && (selectedUser.FirstName+' '+selectedUser.LastName)}'s access to the Ficus dashboard.</h6>   	      		
	    		</div>			
	    		<Button variant={(newstatus > 0) ? "info" : "danger"} type="button" onClick={() => {changeAuthorization(newstatus);}} className="mt-2 animate-up-2">
	    			{(newstatus > 0) ? 'Grant' : 'Revoke'} User Access
	    		</Button>
	      </div>      
	    </Modal.Body>
	  </Modal>  
	  )	
		
	} /* End Change Auth Status Modal */

	const ShowUsers = (userlist) => {

		function AccessIndicator (props) {
			const {accessStatus} = props;		
			const iconColor = (accessStatus === 1 || accessStatus === 3 || accessStatus === 4 ) ? 'green': 'red';
			const icons = {
			    check: CheckIcon,
			    x: XIcon
			};			
			const TagName = icons[(accessStatus === 1 || accessStatus === 3 || accessStatus === 4 ) ? 'check': 'x'];
			return (
				<OverlayTrigger key="access" overlay={<Tooltip id="access" className="m-0 red" >{(accessStatus === 1 ) ? 'Authorized - Operator' : (accessStatus > 0 ) ? 'Authorized' : 'Not Authorized'}</Tooltip>}>
					<TagName className="icon icon-xxs " stroke={iconColor}  />
				</OverlayTrigger>		
			)				
		}

	  const TableRow = (props) => {
	    const { FirstName, LastName, Title, Created, PersonTypeID  } = props;

	    return (
	      <tr>
	        <td className="border-0 statusindicator" style={{ width: 'auto' }} 
	        	onClick={() =>  {setSelectedUser(props); setShowChangeAuthModal((PersonTypeID !== 1));  }}>
						<AccessIndicator accessStatus={PersonTypeID}  />
	        </td>
	        <td className="border-0" style={{ width: 'auto' }}>{FirstName+' '+LastName}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{Title}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{moment(Created).format('L')}</td>
	      </tr>
	    );
	  };

	  return ( 
	  <>
	     <Table responsive className="dashboard table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
	      <thead className="thead-light">
	        <tr>
	          <th className="border-0" style={{ width: 'auto' }}>Access</th>
	          <th className="border-0" style={{ width: 'auto' }}>User Name</th>
	          <th className="border-0" style={{ width: 'auto' }}>Title</th>
	          <th className="border-0" style={{ width: 'auto' }}>Created</th>
	        </tr>
	      </thead>
	      <tbody>
	        {userlist.map(ul => <TableRow key={`persons-${ul.PersonID}`} {...ul} />)}        
	      </tbody>
	     </Table>

			</>
	  );
	}  
	

	useEffect(() => {
		axios.get(endpoint+'/users/authorizedusers.asp', {
			params: { 'companyid': user.companyid }	    			
	  })
	  .then(response => {
	  	const result = response.data;
	  	console.log(result);
	  	setAuthorizedUsers(result);
		})
	  	.catch(error => console.log(error));	
	},[user, endpoint]);


	  	  
  return (
  	<>
    <Card className="text-left mt-3 mb-4 mb-lg-0">
      <Card.Body>
        <h5>Authorized Users</h5>
      	{authorizedUsers && ShowUsers(authorizedUsers)}
    		<Button variant="info" type="button" onClick={() => {setShowAddUserModal(true); }} className="btn-sm mt-2 mb-2 animate-up-2">
    			<UserAddIcon className="icon icon-xs " /> Add Authorized User
    		</Button>
        <p style={{fontSize: "0.7rem"}}><i>Add an authorized user to your Ficus Dashboard account.   An email invitation will be sent to that user to complete their account setup.</i></p>
      </Card.Body>
    </Card>
		<AddUserModal 
			showme={showAddUserModal} 
			onClose={() => {setShowAddUserModal(false)}} /> 
		<ChangeAuthModal 
			showme={showChangeAuthModal} 
			onClose={() => {setShowChangeAuthModal(false)}} /> 
    </>
  );
}

const AddUserModal  = (props) => {
	const {showme, onClose } = props;
	const { 
		user,
		secToken,
		endpoint
	} = useAuth();
  const navigate = useNavigate();
	 
	const firstName = useRef("");
	const lastName = useRef("");
	const title = useRef("");
	const email = useRef("");

	
	function closeMe() {
		onClose();
	}
	
	const sendInvite = (e) => {		 	
  	e.preventDefault();
/*
  	const submitButton = document.querySelector("button[type='submit']");
		
    if (submitted) {
        return;
    }
    setSubmitted(true);
		submitButton.innerText = 'Processing Request...';
		submitButton.disabled = true;
*/

		/* WARNING - HACK */
		const placeholderid = 99000000 + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)

		var personinfo =
			{
		    "firstname":firstName.current.value,
		    "lastname":lastName.current.value,
		    "email": email.current.value,
				"title": title.current.value,
				"companyid": user.companyid,
				"hubspotid": placeholderid,
			}

  	let formData = new FormData(); 
  	Object.keys(personinfo).forEach(key => formData.append(key, personinfo[key]));  	
  	let pinfo = new URLSearchParams(formData).toString()
  	
		axios.post(endpoint+'/users/default.asp', pinfo, {
				params: {'sectoken': secToken, 'action':'addauthorizeduser' }	
			})
		  .then(response => {
		  	if (response.data) {
/*					alert('Authorized User Added:'+response.data); */
					alert('The invitation has been sent!');
		    	(onClose) ? onClose() : navigate('/', {replace: true});
		    } else {
		    	alert('There was a problem adding adding this authorized user.  Please try again.');	    	
		    }
	    })
		.catch(error => console.log(error.response.data));	
			
	};  	
	
	return (
  <Modal centered show={showme} onHide={() => {}}>
    <Modal.Header style = {{alignItems: "normal"}}>
      <Modal.Title>
      	<div className="float-start">
	      	<h4 className="mb-0">Add Authorized User</h4>
	      	<h6><i>{user.companyname}</i></h6>
	      </div>
      </Modal.Title>
      <Button variant="close" aria-label="Close" onClick={closeMe} />
  	</Modal.Header>
      <Modal.Body>
      <div className="small studentstatus">
    		<div className="mb-3">
	      		Please provide the following information for the person you would like to invite as an authorized user to your Ficus Operator dashboard.  	      		
    		</div>
			
        <Form id="myform">
          <Row>
            <Col md={4} className="mb-2">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter first name" ref={firstName}/>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-2">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter  last name" ref={lastName}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-2">
              <Form.Group id="email">
                <Form.Label>Email </Form.Label>
                <Form.Control required type="email" placeholder="emailaddress@company.com"  ref={email}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-2">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Control required type="text" placeholder="Title" ref={title}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className="">
            <Col  className="d-grid mb-3">
	        		<Button variant="info" type="button" onClick={sendInvite} className="mt-2 animate-up-2">
	        			Add User and Send Invitation
	        		</Button>
            </Col>
          </Row>
				</Form>
      </div>      
    </Modal.Body>
  </Modal>  
  )	
	
} /* End Add User Modal */





export const XactionHistoryWidget = () => {
  const InvoiceItem = (props) => {
    const { invoiceNumber, invoiceDate, invoiceStatus, last } = props
      , invoiceDateFormatted = invoiceDate.format("MMMM DD, YYYY")
      , isInvoicePaid = invoiceStatus === "paid"
      , listItemClassNames = last ? "py-3" : "border-bottom py-3";

    return (
      <ListGroup.Item className={listItemClassNames}>
        <Row className="align-items-center">
          <Col>
            <h6 className="mb-1">
              <a href="#!">Invoice #{invoiceNumber}</a>
            </h6>
            <small className="text-gray-700">
              Billed {invoiceDateFormatted}
            </small>
          </Col>
          <Col xs="auto">
            {isInvoicePaid ? (
              <Badge bg="success" className="rounded px-3 py-2">
                <span className="text-uppercase fw-bolder">
                  Paid
                </span>
              </Badge>
            ) : (
              <Button variant="gray-800" size="sm">
                Pay now
              </Button>
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Card border="0" className="shadow p-0 p-md-4">
      <Card.Header className="border-bottom p-3">
        <h5>Order History</h5>
        <Card.Text className="mb-0">
          Manage billing information and view receipts
        </Card.Text>
      </Card.Header>
      <Card.Body className="px-0 py-0">
        <ListGroup>
          <InvoiceItem
            invoiceNumber="120345"
            invoiceStatus="issued"
            invoiceDate={moment().subtract("1", "day")}
          />
          <InvoiceItem
            last={true}
            invoiceNumber="120344"
            invoiceStatus="paid"
            invoiceDate={moment().subtract("2", "days")}
          />
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
