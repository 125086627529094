import React, { useState, useEffect } from "react";
import moment from 'moment';
import axios from "axios";
import { Container, Col, Row, Card, Table, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AnnotationIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { useAuth } from "pages/access/useAuth"; 


export const ParticipantList = (props) => {
	const { companyid, setCurrentPersonID, setCurrentPersonName, setShowHistoryModal  } = props;
	const { 
		endpoint
	} = useAuth(); 	
	const [students, setStudents] = useState([]); 

	useEffect(() => {
	  axios.get(endpoint+"/student/", {
  		params: { 
  			'companyid': companyid, 
  			'orderby': 'status',
  			'activedays': 1000
  			}	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	console.log(theResult);
		  	setStudents(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[companyid, endpoint]);
	
  const TableRow = (props) => {
    const { index } = props;

    return (
      <tr className="border-bottom">
	        <td>
	          <Card.Link href="#" className="text-primary fw-bold">
	            {index}
	          </Card.Link>
	        </td>
	        <td className="border-0" style={{ width: 'auto' }}>{props.IsCurrent}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{props.FirstName+' '+props.LastName}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{moment(props.Created).format('L')}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{props.StudentSummaryStatus}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{props.StudentStatus}
            <OverlayTrigger placement="bottom" overlay={<Tooltip className="m-0">Status History...</Tooltip>}>
		        	<AnnotationIcon className="ms-1" style={{height:'1.2em'}} 
		        	onClick={() => {setCurrentPersonID(props.PersonID); 
		        		setCurrentPersonName(props.FirstName+' '+props.LastName); 
		        		setShowHistoryModal(true)}}
		        		title="Status History..."/>
            </OverlayTrigger>
	        </td>
	        <td className="border-0" style={{ width: 'auto' }}>{moment(props.AssignedDate).format('MM/DD/YYYY h:mm a')}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{props.FAFSA}</td>  
	        <td className="border-0" style={{ width: 'auto' }}>{props.Transcript}</td>   
	        <td className="border-0" style={{ width: 'auto' }}>{props.EmploymentVerified}</td>  
	        <td className="border-0" style={{ width: 'auto' }}>{moment(props.EmploymentVerifiedDate).format('MM/DD/YYYY h:mm a')}</td>  
	        <td className="border-0" style={{ width: 'auto' }}>{props.LocationName}</td>
      </tr>
    );
  };

  return (
    <Card border="0" className="mb-4">
      <Card.Body>
        <Table responsive className="statuslist text-start table-centered table-nowrap rounded mb-0" size="sm">
          <thead className="thead-light">
            <tr>
              <th className="border-0 rounded-start">#</th>
              <th className="border-0">Aging</th>
              <th className="border-0">Name</th>
              <th className="border-0">Create Date</th>
              <th className="border-0">Phase</th>
              <th className="border-0">Status</th>
              <th className="border-0">Status Change Date</th>
              <th className="border-0">FAFSA</th>
              <th className="border-0">Transript</th>
              <th className="border-0">Emp Status</th>
              <th className="border-0">Emp Status Change</th>
              <th className="border-0">Location</th>
            </tr>
          </thead>
          <tbody className="border-0">
            {students.map((s, ind) => <TableRow key={`student-id-${s.PersonID}`} index={ind + 1} {...s} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default () => {
	let {id} = useParams()
	const { endpoint } = useAuth(); 	
	
	const [company, setCompany] = useState([]); 
	const [showHistoryModal, setShowHistoryModal] = useState(false); 
	const [currentPersonID, setCurrentPersonID] = useState(); 
	const [currentPersonName, setCurrentPersonName] = useState(); 

	useEffect(() => {
	  axios.get(endpoint+"/company/", { 
  		params: { 
  			'companyid': id, 
  			}	
		  })  
		  .then(response => {
		  	const theResult = response.data[0];
		  	console.log(theResult);
		  	setCompany(theResult);
		  })
	  	.catch(error => console.log(error));	
	},[id, endpoint]);

	const HistoryModal  = (props) => {
		const {onClose } = props;
		const { 
			endpoint
		} = useAuth();
		
		const [getData, setGetData] = useState(true);
		const [historyList, setHistoryList] = useState([]);
		
		const onShowHandler = () => {
				setGetData(false)
			  axios.get(endpoint+"/student/statushistory.asp", {
					  		params: { 
					  			'personid':currentPersonID
					  		}	
				  })  
				  .then(response => {
				  	const theResult = response.data;
				  	setHistoryList(theResult);
				  })
			  	.catch(error => console.log(error));
		}
		
		function closeMe() {
			onClose();
			setGetData(true)
		}
		
		const ShowHistoryTable = (props) => {
		  const TableRow = (props) => {
		    const { StudentStatusID, StudentSummaryStatus, StudentStatus, AssignedDate,  } = props;

		    return (
		      <tr>
		        <td className="border-0" style={{ display:'none', width: 'auto' }}>{StudentStatusID}</td>
		        <td className="border-0" style={{ width: 'auto' }}>{StudentSummaryStatus}</td>
		        <td className="border-0" style={{ width: 'auto' }}>{StudentStatus}</td>
		        <td className="border-0" style={{ width: 'auto' }}>{moment(AssignedDate).format('L')}</td>
		      </tr>
		    );
		  };

		  return (
		  <>
		  	 <div>Poo</div>
		     <Table responsive className="dashboard table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
		      <thead className="thead-light">
		        <tr>
		          <th className="border-0" style={{ display:'none', width: 'auto' }}>StudentStatusID</th>
		          <th className="border-0" style={{ width: 'auto' }}>Phase</th>
		          <th className="border-0" style={{ width: 'auto' }}>Status</th>
		          <th className="border-0" style={{ width: 'auto' }}>Date Assigned</th>
		        </tr>
		      </thead>
		      <tbody>
		        {historyList.map(c => <TableRow key={`hid-${c.StudentStatusHistoryID}`} {...c} />)}        
		      </tbody>
		     </Table>

				</>
		  );
		}; // END OF HistoryTable  

		return (
	  <Modal centered show={showHistoryModal} onHide={() => {}} onShow ={showHistoryModal && getData && onShowHandler()}>
	    <Modal.Header>
	      <Modal.Title>
	      	<h6>{currentPersonName} Status History</h6>
	      </Modal.Title>
	      <Button variant="close" aria-label="Close" onClick={closeMe} />
	  	</Modal.Header>
	      <Modal.Body>
	      {historyList &&  ShowHistoryTable(historyList)}
	    </Modal.Body>
	    <Modal.Footer>
	      <Button variant="info" size="sm" onClick={closeMe}>
	      	Close
	      </Button>
	    </Modal.Footer>
	  </Modal>  
	  )	


	}; //END OF HistoryModal

  return (
    <main>
      <section className="d-flex align-items-center mt-lg-0 bg-soft">
        <Container className="full-width-max"> 
					<Row className="justify-content-center mt-2 ">
						<Col xs={12}  className="text-center ">
							<h5>Participant Listing for {company.name}, {company.operator}</h5>
						</Col>
					</Row>
{/*
					<Row className="justify-content-center mb-3">
							<Col xs={8} sm={8} md={6}  className="text-start small">
							<div><b>Location Name:</b>  {company && location.DisplayLocationName} </div>
							<div><b>City, State:</b>  {(location && location.City)+', '+(location && location.State)} </div>
							<div><b>Location Number:</b>  {location && location.DisplayLocationNum} </div>
							<div><b>Operator LLC:</b>  {company && company.CompanyName} </div>
							<div><b>Operator Name:</b>  {(location && location.FirstName)+' '+(location && location.LastName)} </div>
						</Col>
					</Row>
*/}
					<Row> 
						<Col xs={12}  className="text-center">
							<ParticipantList companyid = {id} 
								setCurrentPersonID = {setCurrentPersonID}  
								setCurrentPersonName = {setCurrentPersonName}  
								setShowHistoryModal = {setShowHistoryModal}/>
						</Col>
					</Row>
        </Container> 
				<HistoryModal onClose={() => setShowHistoryModal(false)}/>
      </section>
    </main>
  );



}; // END OF ParticipantList